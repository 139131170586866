import {createRouter, createWebHistory} from "vue-router"

import Home from '@/App.vue'
// import Vue from 'vue'



const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: '/',
            component: Home,
            meta:{
                title: 'Home- BrunoDevs'
            }
        }
    ]
})
router.beforeEach((to)=>{
    document.title = to.meta?.title
})
// const app = Vue.createApp({})

// app.use(router)
// app.mount('#app')

export default router