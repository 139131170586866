import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import router from './router'



createApp(App).mount('#app')


App.use(router)