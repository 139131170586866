<template>
   
    <div class="bg-gradient-to-tr from-[#082F49] to-[#072d47] justify-center flex p-5">
        <br>  <br>
        <h2 class="text-white text-2xl font-bold">Fale comigo Agora!! <span class="rounded-md bg-red-50 py-1 px-3 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Manutenção</span></h2>


    </div>
<div class=" bg-gradient-to-tr from-[#082F49] to-[#072d47] items-center flex justify-center p-5">


    <form class="w-full max-w-sm">
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label class="block text-white font-medium md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name" id="nome">
        Seu nome
      </label>
    </div>
    <div class="md:w-2/3">
      <input v-model="nomeUser" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-full-name" type="text" placeholder="Meu nome é...">
    </div>
  </div>
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label  class="block text-white font-medium md:text-right mb-1 md:mb-0 pr-4" for="texto" >
        Seu E-mail
      </label>
    </div>
    <div class="md:w-2/3">
      <input v-model= "emailUser" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-password" type="text" placeholder="fulano@gmail.com">
    </div>
  </div>
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      
    </div>
    <div class="md:w-2/3">
        <textarea v-model="textoUser" id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Mensagem"></textarea>
        <button class="shadow w-full bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded" type="button">
        Enviar
      </button>
    </div>
  </div>

  <!-- <div class="mb-6 md:w-2/3 md:items-center">

      <button class="shadow w-full bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded" type="button">
        Enviar
      </button>
</div> -->
  


</form>

  </div>
  



</template>



<script>
export default{
    name:'SAC'
}
</script>