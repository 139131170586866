
<template>
    <Head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
    rel="stylesheet"
  />
</Head>


<section class="Font-Sobre">
    
    <div class="md:h-auto mx-auto bg-gradient-to-tr from-[#082F49] to-[#072d47] ">
<br>
  <div class="mx-auto  px-4 py-6 sm:px-6 lg:px-8">

    <a id="portfolio"></a>

      <h1 class="tracking-tight text-end text-cyan-500 p-px">Minha experiência profissional</h1><br><br>

      <h2 class="text-center text-white text-5xl font-bold"> PROTEÇÃO ANTI-DDOS  </h2><br>

      <h3 class="text-center text-lg font-bold text-zinc-400"> Especialista em mitigação Layer7 OSI</h3>
      <br>
      <p class="text-white text-center">
        A proteção na camada 7, também conhecida como application layer, é essencial para a segurança de aplicações e serviços web. <br>
        Concentrando-se no nível de aplicação do modelo OSI, essa abordagem visa prevenir ataques específicos, como injeções SQL e cross-site scripting (XSS). Além disso, 
        proporciona controle de acesso, autenticação robusta e proteção contra ameaças emergentes. Ao analisar o tráfego na camada de aplicação. <br>A proteção na <b>Layer 7</b> identifica e bloqueia padrões de comportamento malicioso, garantindo a conformidade com regulamentações de segurança e prevenindo vazamentos de informações sensíveis.<br>
         Essa camada desempenha um papel crucial na manutenção da integridade, confidencialidade e disponibilidade dos dados manipulados pelas aplicações web.
        </p><br>
        <div class=" mbg-gradient-to-tr from-[#082F49] to-[#072d47]">
            <section class="Font-Sobre">
  <span class="block text-white text-center bg-indigo-800 px-4 py-2 rounded">1. <b>Identificação de Tráfego Malicioso</b></span>
  <p class="text-white text-center">A análise de logs desempenha um papel crucial na detecção e mitigação de ameaças, incluindo <b>Inject SQL</b>, <b>Cross-Site Scripting (XSS)</b>, <b>Cookie Overflow</b>, <b>Ataques de Negação de Serviço (DDoS)</b>, <b>Entidades Externas – XML</b>, <b>injeções por texto e Shells</b>. <br>
    Ao verificar nos registros, é possível identificar padrões suspeitos e comportamentos maliciosos.</p>
  <span class="block text-white text-center bg-indigo-800 px-4 py-2 mt-2 rounded">2. <b>Avaliação de Desempenho</b></span>
  <p class="text-white text-center">O <b>benchmark</b> compara o desempenho do site em relação a padrões e métricas estabelecidos. Isso inclui tempo de carregamento de páginas, velocidade de resposta do servidor e eficiência no processamento de solicitações. 
    <br>Identificar possíveis melhorias no desempenho é fundamental para garantir uma experiência do usuário otimizada.</p>
  <span class="block text-white text-center bg-indigo-800 px-4 py-2 mt-2 rounded">2.1 <b>Análise de Segurança</b></span>
  <p class="text-white text-center">
No <b>Benchmark</b> inclui a avaliação de segurança do site, levantando possíveis vulnerabilidades. Contribuindo com medidas de proteção de modo a garantir a integridade e funcionalidade do site contra ameaças <b>cibernéticas</b>.
  </p>
  <span class="block text-white text-center bg-indigo-800 px-4 py-2 mt-2 rounded">2.2 <b> Otimização de Conteúdo</b></span>
  <p class="text-white text-center">
    Por meio do <b>benchmark</b>, torna-se viável quantificar o tempo necessário para que o conteúdo do site seja transmitido eficientemente ao navegador do usuário. Quanto mais meticulosamente estruturado, mais eficaz será a entrega do conteúdo, desempenhando um papel fundamental na otimização da estratégia de <b>SEO (Search Engine Optimization)</b>.<br> Este processo não apenas revela a eficiência da entrega de informações, mas também desempenha um papel crucial na maximização da visibilidade do site nos motores de busca, promovendo uma experiência de usuário aprimorada e consistentemente envolvente.
  </p>
  <span class="block text-white text-center bg-indigo-800 px-4 py-2 mt-2 rounded">3. <b> Resposta a Incidentes </b></span>
  <p class="text-white text-center">
    A resposta a incidentes é vital para proteger aplicações, dados sensíveis e a reputação da organização. <br>Uma abordagem eficaz não apenas mitiga ameaças imediatas, mas também contribui para aprimorar a postura de segurança geral da organização.
  </p>



</section>
</div>

        

<br><br>
<h1 class="tracking-tight text-end text-cyan-500 p-px">Projetos já desenvolvidos</h1><br><br>
<h2 class="text-center text-white text-4xl font-bold"> Tecnologia Shield  </h2><br>




<div class="flex flex-col justify-center items-center">
  <div><figure class="max-w-lg">
  <img class=" h-auto rounded-lg" width="500" height="500" src="../assets/shield1.jpg" alt="image description">
  <figcaption class="text-sm text-center text-white dark:text-gray-400">Shield</figcaption>
</figure></div>
  <div><figure class="max-w-lg">
  <img class=" h-auto rounded-lg" width="500" height="500" src="../assets/shield2.jpg" alt="image description">
  <figcaption class="text-sm text-center text-white dark:text-gray-400">Shield</figcaption>
</figure>
</div>

</div>

<p class="text-white text-center"><br>
    O objetivo primordial da tecnologia <b>SHIELD</b> foi identificar e mitigar ataques provenientes da internet diretamente nos servidores em que estava hospedada. 
    <br>Sua eficácia alcançou o pico máximo de mitigação, atingindo 10,36 milhões de requisições fraudulentas bloqueadas.      <br>Essa tecnologia foi interligada com a <b>AbuseIPDB</b>.<br></p>
    <p class="text-white text-center"><br>
    O <b>AbuseIPDB</b> é um serviço online que fornece informações e monitoramento de endereços IP associados a atividades maliciosas na internet. 
    <br>Este serviço permite que usuários denunciem e consultem endereços IP suspeitos, auxiliando na identificação e mitigação de ameaças cibernéticas, como ataques de malware, phishing e abuso de servidores. <br>
    A <b>AbuseIPDB</b> desempenha um papel essencial na comunidade de segurança cibernética, promovendo a colaboração e o compartilhamento de informações para fortalecer as defesas contra atividades maliciosas online</p>
        <div class=" flex justify-center item-center p-2">
    <a  href="https://www.abuseipdb.com/user/53105" title="AbuseIPDB is an IP address blacklist for webmasters and sysadmins to report IP addresses engaging in abusive behavior on their networks">
	<img src="https://www.abuseipdb.com/contributor/53105.svg" alt="AbuseIPDB Contributor Badge" width="401" height="401" style="width: 401px;">
</a>
</div>
<br><br>

    <h2 class="text-center text-white text-4xl font-bold"> Tecnologia Backup  <i class="fa-regular fa-file-zipper"></i> </h2><br>
<h3 class="text-white  text-center"> Desenvolvimento de tecnologia BACKUP</h3>



<p class="text-white text-center"><br>
O objetivo primordial dessa técnologia é realizar <b>backups</b> automatizados de uma estação Bash para a plataforma Dropbox de forma remota, substituindo os backups antigos e ordenando por data, essa tecnologia foi vendida .

</p>
 
<br><br>

    <h2 class="text-center text-white text-4xl font-bold"> Tecnologia Litespeed  <i class="fa-brands fa-cpanel"></i> </h2><br>
<h3 class="text-white  text-center"> Tecnologia integrada ao cPanel</h3>



<p class="text-white text-center"><br>
  O objetivo primordial da tecnologia exclusiva citada é de atuação no WebServer cPanel, no entanto, é de cunho Mitigacional, <a href="#"></a>atua hoje na maior empresa de hospedagem do jogo Lineage2, hospedagem de Sites, Vps, Datacenters e por motivo de privacidade e segurança não será exposta.
</p>
<!-- <div class=" flex justify-center item-center p-2"><a href="https://l2jcenter.com"><img width="60" height="60" src="../assets/l2jcenterlogo2.png"></a></div> -->
 






    
</div>

</div>


</section>

<SAC/>

</template>

<script>
export default{
    
    name:'SobreMim',
    mounted(){
        document.title= "BrunoDEVS - Especialista em Cybersecurity Defense";   
    }
  
}
</script>



<style src="../assets/tailwind.css"></style>