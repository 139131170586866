import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/vue"


<script>
// import CabecaSite from "./components/Header.vue";
import SobreMim from "./components/Sobre.vue"
import CabecaSite2 from "./components/Header2.vue"
import SAC from "./components/FaleAgora.vue"
import FimSite from "./components/Footer.vue"
// Exports 
export default {
  components: {
    // CabecaSite,
    SobreMim,
    CabecaSite2,
    SAC,
    FimSite,
  },
  
};
</script>



////////////////////////////////////////////////////////////////////////////////////////////



<template>
  <Analytics/>
  <SpeedInsights/>
 
    <CabecaSite2 />
    



  <main>
    
    <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">

  <div class="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
    <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#082F49] to-[#00d4ff] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
  </div>
  <div class="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
    <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr  from-[#082F49] to-[#00d4ff] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:mx-0">
      <a id="sobre"></a>
      <p class="text-white">Olá, meu nome é </p>
      <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Bruno Lopes.</h2>
      <p class="mt-6 text-lg leading-8 text-gray-300">Cultivando conceitos para a web com dedicação e segurança.</p>
    <br>  <p class="mt-6 text-lg leading-8 text-gray-300">Como especialista em redes de computadores, minha trajetória profissional é marcada pelo comprometimento com a excelência.
     <br> Com vasta experiência na área, concentro-me na gestão e otimização de redes, destacando-me como especialista em segurança de servidores web.
    </p>
    </div>

  </div>
</div>


  </main>
  <main>
    
     <div class="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true"></div>
    <div class="md:h-fullcontainer mx-auto bg-gradient-to-tr from-[#082F49] to-[#072d47] ">

<div class=" grid mx-auto py-8 lg:px-12 max-w-max">
  <div class="text-align: center;"> <p class="text-white">Status Server <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Manutenção</span></p></div>
</div>
<a id="server"></a>
<p class="ps-3.5 text-white text-center"> Status do servidor nessas informaçõe são exibidas em tempo real, informações básicas do servidor hospedeiro (RAM,CPU)</p>

<div class="grid grid-cols-2 gap-3 w-auto h-5 p-8 "> <!-- ROW-->

<div class="bg-blue-500 text-white p-3 rounded-lg shadow-sm">
  <h2 class="text-x1 font-semibold mb-2"><i class="text-white fas fa-memory"></i> Memoria RAM</h2>
 <p class="text-x1">0/0GB</p>
</div>

<div class="bg-blue-500 text-white p-3 rounded-lg shadow-lg ">
  <h2 class="text-x1 font-semibold mb-2"> <i class="text-white fas fa-microchip"></i> CPU</h2>
  <p>AMD EPYC-Milian Processor </p></div>
  <div class="bg-blue-500 text-white p-3 rounded-lg shadow-lg ">
  <h2 class="text-x1 font-semibold mb-2"> <i class="text-white fas fa-shield"></i> Firewall</h2>
  <p>On </p></div>
  <div class="bg-blue-500 text-white p-3 rounded-lg shadow-lg ">
  <h2 class="text-x1 font-semibold mb-2"><i class="text-white fas fa-ethernet"></i> Network</h2>
  <p>2Gbps </p>  </div>
</div>
</div>
<br><br><br><br><br><br>

  
  <SobreMim/>

  <SAC/>

  <FimSite/>




    
  </main>


</template>
<style scoped>
.X1{
  font-family:Arial, Helvetica, sans-serif;
  font-size: 14pt;
  color:aliceblue

}

</style>

<style src="./assets/tailwind.css"></style>



