<template>
    <div>
      <!-- Navbar -->

      <nav class="p-4 bg-gradient-to-tr from-[#082F49] to-[#072d47]">
        <!-- Logo -->
        <div class="flex flex-col justify-center items-center">
            <div class="flex flex-row-reverse ">

          <span class="text-white text-lg font-semibold"><a href="https://brunodevs.com/">BrunoDEVS</a></span>
        </div>
        <br>
  
        <!-- Botão de Menu para dispositivos móveis -->
       
       <div class=" row-auto">
        <button
          @click="toggleMobileMenu"
          class="lg:hidden text-white focus:outline-none shadow-slate-200  justify-end align-middle"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
        
    </div>
        <!-- Links da Navbar --> 
        <div
          :class="{ 'hidden': !isMobileMenuOpen }"
          class="flex-grow items-center justify-end lg:flex lg:items-center lg:w-auto"
        >
          <a
            href="#sobre"
            class="block lg:inline-block text-white hover:text-gray-300 mx-2 mt-4 lg:mt-0"
          >
            Sobre mim
          </a>
          <a
            href="#server"
            class="block lg:inline-block text-white hover:text-gray-300 mx-2 mt-4 lg:mt-0"
          >
            Server Status
          </a>
          <a
            href="#portfolio"
            class="block lg:inline-block text-white hover:text-gray-300 mx-2 mt-4 lg:mt-0"
          >
            Meu Portfólio
          </a>
          <!-- <a
            href="#"
            class="block lg:inline-block text-white hover:text-gray-300 mx-2 mt-4 lg:mt-0"
          >
            Meus Projetos
          </a> -->
        </div>
    </div>
      </nav>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CabecaSite2',
    data() {
      return {
        isMobileMenuOpen: false,
      };
    },
    methods: {
      toggleMobileMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Adicione estilos específicos aqui, se necessário */
  </style>
  